'use client';

import Link from 'next/link';
import { useRouter } from 'next/navigation';

import useAppLanguage from '../../../hooks/useAppLanguage';
import { PUBLIC_ROUTES } from '../../../routes/routes';
import { styles } from '../../Parts/v2/styles';
import { ANON_USER_KEYS, USER_TYPE_ENUMS } from '../types';
import OnboardHeader from './OnboardHeader';

const localStyles = {
  card: 'rounded-lg shadow-card bg-white py-14 px-2 w-full max-sm:p-4 hover:bg-blue-50 hover:cursor-pointer',
  cardInner: 'text-center space-y-6 max-sm:space-y-2',
};
const SSO_URL = process.env.NEXT_PUBLIC_WS_AUTH_URL ?? '';

export default function Landing() {
  const { translate } = useAppLanguage();
  const router = useRouter();

  const handleUserTypeSelect = (type: USER_TYPE_ENUMS) => {
    // send to email form page with "type" params set
    router.push(`${PUBLIC_ROUTES.EMAIL}?${ANON_USER_KEYS.TYPE}=${type}`);
  };

  return (
    <div className="space-y-16 max-sm:space-y-10">
      <OnboardHeader subtext="Start by telling us about you." />
      <div className="space-y-16">
        <div className="flex gap-y-6 gap-x-6 items-stretch max-sm:flex-col">
          <div
            className={localStyles.card}
            onClick={() => handleUserTypeSelect(USER_TYPE_ENUMS.PROVIDER)}
            data-testid="wonderschool-provider-staff"
          >
            <div className={localStyles.cardInner}>
              {/* TODO: be careful of the image refs as they are relative to root */}
              <img className="mx-auto" src="../images/provider_1.svg" alt="provider/staff image" />
              <div>
                <div className="font-bold">{translate("I'm a child care provider")}</div>
                <div>{translate('or staff member')}</div>
              </div>
            </div>
          </div>
          <div
            className={localStyles.card}
            onClick={() => handleUserTypeSelect(USER_TYPE_ENUMS.FAMILY)}
            data-testid="wonderschool-parent-family"
          >
            <div className={localStyles.cardInner}>
              {/* TODO: be careful of the image refs as they are relative to root */}
              <img className="mx-auto" src="../images/family_1.svg" alt="parent/family image" />
              <div>
                <div className="font-bold">{translate("I'm a parent")}</div>
                <div>{translate('or family member')}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-300 pt-6 text-center" data-testid="wonderschool-log-in">
          {translate('Already have an account?')}&nbsp;
          <span className={styles.inlineLink}>
            <Link href={SSO_URL}>{translate('Log in')}</Link>
          </span>
        </div>
      </div>
    </div>
  );
}
